import React from 'react';
import { Link } from 'gatsby';
import isEmail from 'validator/lib/isEmail';
import { useImmer } from 'use-immer';

import Layout from '../components/layout';
import SEO from '../components/seo';

const Support = () => {
    const [state, updateState] = useImmer({ subject: '', email: '', message: '', success: false, errors: [], isLoading: false });

    const handleChange = ({ target }) => {
        const { name, value } = target;
        updateState((draft) => {
            draft[name] = value;
            draft.errors = [];
            draft.success = false;
        });
    };

    const handleSubmit = () => {
        const errors = [];
        const { subject, email, message } = state;

        updateState((draft) => { draft.isLoading = true; });

        if (!subject.length) {
            errors.push('Veuillez entrer un sujet.');
        }

        if (!isEmail(email)) {
            errors.push('Veuillez entrer un e-mail correct.');
        }

        if (!message.length) {
            errors.push('Veuillez entrer un message.');
        }

        updateState((draft) => { draft.errors = errors; });

        if (!errors.length) {
            fetch('https://n8n.ethibox.fr/webhook/807cdcbc-b189-4f3d-9e66-9db3c47907cf', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, subject, message }),
            });

            setTimeout(() => {
                updateState((draft) => {
                    draft.subject = '';
                    draft.message = '';
                    draft.email = '';
                    draft.success = true;
                    draft.errors = [];
                    draft.isLoading = false;
                });
            }, 1500);
        } else {
            updateState((draft) => { draft.isLoading = false; });
        }
    };

    const { subject, email, message, isLoading, errors, success } = state;

    return (
        <Layout>
            <SEO title="Support" />
            <section className="pt-28 pb-10">
                <h3 className="text-center mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                    Support client
                </h3>

                <p className="text-center mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
                    Posez toutes vos questions !
                </p>

                <div className="container m-auto mt-10 max-w-3xl px-4 lg:px-0">
                    <div className="bg-gray-50 border border-gray-300 text-gray-700 px-4 py-3 rounded relative" role="alert">
                        <p className="font-bold">FAQ</p>
                        <p className="block sm:inline">
                            Avant d&apos;envoyer votre message, vérifiez que la réponse à votre question ne se trouve pas déjà <Link to="/faq">dans la F.A.Q</Link>
                        </p>
                    </div>

                    <div className="flex flex-wrap -mx-3 mb-6 mt-10">
                        <div className="w-full px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 required" htmlFor="subject">Sujet</label>
                            <input
                                className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                maxLength="120"
                                name="subject"
                                type="text"
                                placeholder="Soyez explicites. 120 caractères maximum."
                                onChange={handleChange}
                                value={subject}
                            />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 required" htmlFor="email">E-mail</label>
                            <input
                                className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                name="email"
                                onChange={handleChange}
                                value={email}
                                type="email"
                                placeholder="Votre adresse email (ex: john@protonmail.com)"
                            />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-3">
                        <div className="w-full px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 required" htmlFor="message">Quel est votre question ?</label>
                            <textarea
                                className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                name="message"
                                onChange={handleChange}
                                value={message}
                                placeholder="Plus vous donnerez de détails, et plus il sera facile de vous aider"
                                rows="8"
                            />
                        </div>
                    </div>
                    <div className={`bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-6 ${errors.length ? 'block' : 'hidden'}`} role="alert">
                        <strong className="font-bold">Erreur</strong>
                        <span className="block sm:inline">
                            <ul className="list-inside list-disc ml-2 mt-2">
                                { errors.map((error) => (<li key={error}>{error}</li>)) }
                            </ul>
                        </span>
                    </div>
                    <div className={`bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-6 ${success ? 'block' : 'hidden'}`} role="alert">
                        <p className="font-bold">Message envoyé avec succès !</p>
                        <p className="block sm:inline">Vous recevrez une réponse dans les plus brefs délais !</p>
                    </div>
                    { isLoading ? (
                        <button className="button inline-flex items-center bg-gray-800 hover:bg-gray-900 text-white py-2 px-3 rounded" type="button" disabled>
                            <span className="w-4 h-4 mr-2">
                                <img src="/spinner.svg" alt="spinner" className="spinner" />
                            </span>
                            <span>Envoi en cours</span>
                        </button>
                    ) : (
                        <button className="button inline-block bg-gray-800 hover:bg-gray-900 text-white py-2 px-3 rounded" type="button" onClick={handleSubmit}>
                            Envoyer le message
                        </button>
                    ) }
                </div>
            </section>
        </Layout>
    );
};

export default Support;
